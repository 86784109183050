import {createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { makeRequest } from '../Component/Global/makeRequest';

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    const logins = async(username, pass) => {
        const res = await makeRequest.post('/user/login', {username,pass},{withCredentials: true, credentials: 'include'})
        setCurrentUser(res.data.value)
    }
    const logout = async() => {
        console.log('arrivo logout')
        const res = await makeRequest.post('/user/logout',{},{withCredentials: true, credentials: 'include'})
        setCurrentUser(null);
    }
    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser])
    return (
        <AuthContext.Provider value={{currentUser, logins, logout}}>
            {children}
        </AuthContext.Provider>
    )
}