import React, { useContext, useEffect, useState } from 'react';
import {Col, Container, Row } from 'react-bootstrap'
import MenuNav from '../Global/MenuNav';
import MatchStats from '../Global/MatchStats';
import { makeRequest } from '../Global/makeRequest';
import Footer from '../Global/Footer';
import Risultati from '../Global/Risultati';
import Girone from '../Global/Girone';
import Sponsor from '../Sponsor/Sponsor';
import { AuthContext } from '../../Auth/authContext';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../Global/Logout';
function Home() {
    const [matchid, setMatchID] = useState(0);
    const [squadre, setSquadre] = useState([]);
    const [gironeA, setGironeA] = useState([]);
    const [gironeB, setGironeB] = useState([]);  
    const [user, setCurrentUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
            }
        })
        makeRequest.get('/partite/nextmatch')
        .then(res => { console.log(res.data); setMatchID(res.data[0].ID)})
        .catch(err => console.log(err));
        makeRequest.post('/squadre/getall')
        .then(res=>
            setSquadre(res.data)
        )
        makeRequest.post('/partite/getfromgirone',{id: 1})
        .then(res=>
            setGironeA(res.data)
        )
        makeRequest.post('/partite/getfromgirone',{id: 2})
        .then(res=>
            setGironeB(res.data)
        )
    },[])
    return(
        <> 
        <div className='containerPrimary'>
            <MenuNav active="/" user={user}/>
            <Sponsor/>
            <Container>
                <Row>
                    <MatchStats showButton={true} id={matchid}/>
                    <Col style={{textAlign: 'center'}} xs={12} md={6}>
                        <Risultati allteam={squadre} match={gironeA} props={{label: '- Girone A', align: 'center', md: 12}}/>
                    </Col>
                    <Col style={{textAlign: 'center'}} xs={12} md={6}>
                        <Risultati allteam={squadre} match={gironeB} props={{label: '- Girone B', align: 'center', md: 12}}/>
                    </Col>
                    <Col style={{textAlign: 'center'}} xs={12} md={6}>
                        <h1>Classifica Girone A</h1>
                        <Girone id={1}/>
                    </Col>
                    <Col style={{textAlign: 'center'}} xs={12} md={6}>
                        <h1>Classifica Girone B</h1>
                        <Girone id={2}/>
                    </Col>
                </Row>
            </Container>
            <Sponsor/>
            <Footer/>
        </div>
        </>
    );
}
export default Home;