import {React, useContext, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { App, PersonPlus, BoxArrowRight, Houses, Calendar2Week, CashCoin, HouseAdd, House, People} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/esm/Button';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Auth/authContext';
import { Image, NavDropdown } from 'react-bootstrap';
function MenuNav({user, active}) {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleTrue = () => {
    setShow(true);
  }
  const handleFalse = () => {
    setShow(false);
  }
  const handleLogout = async() => {
    try {
      await logout()
      navigate('/login');
    }
    catch(err) {
      console.log(err);
    }
  }
  return (
        <>
            <Navbar variant="dark" bg="dark" data-bs-theme="dark" key={'md'} expand={'md'} className="bg-body-tertiary mb-3">
              <Container fluid>
                <Navbar.Brand href="#">TDQ</Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-md`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                  placement="end"
                  onShow={handleTrue}
                  onHide={handleFalse}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                      TDQ
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav activeKey={active} className="justify-content-center flex-grow-1 pe-3">
                      <Nav.Link href="/">Home</Nav.Link>
                      <Nav.Link href="/team">Squadre</Nav.Link>
                      <NavDropdown style={{borderBottom: !user && show ? '1px solid black' : null}}
                        active={active === "/girone" ? true : false}
                        title="Gironi"
                        id={`offcanvasNavbarDropdown-expand-md`}
                      >
                        <NavDropdown.Item href="/opengirone?id=A">A</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/opengirone?id=B">B</NavDropdown.Item>
                      </NavDropdown>
                      {user ?<Nav.Link href="/admin">Admin</Nav.Link> : null}
                      {user ? <Nav.Link style={{borderBottom: show ? '1px solid black' : null}} onClick={handleLogout}>Logout</Nav.Link> : null}
                      
                    </Nav>
                    {show ? <div style={{textAlign: 'center'}} className="mt-3 d-block">
                      <a href="https://realizzaonline.it/" target="_blank"><Image src="logorealizzablack.png"
                      height= "150"
                      width= "150"/></a>
                      <p><b><a style={{textDecoration: 'none', color: 'black'}} target="_blank" href="https://realizzaonline.it/">Realizza Online</a></b> ©<b>2024</b> </p>
                    </div>
                    : null
                    }
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
        </>
  );
}

export default MenuNav;