import React, { useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';

function CountDown({date, hour}) {
    const [hourn, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const [distances, setDistance] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const startUpdate = (countDownDate) => {
        let caricato = false;
        var x = setInterval(function() {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor(((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) + days * 24);
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setHour(hours);
            setMinute(minutes);
            setSecond(seconds);
            setDistance(distance);
            if(distance < 0)
                clearInterval(x);
            setLoaded(true);
        },1000);
    }
    useEffect(() => {
        const countDownDate = new Date(date+' '+hour+':00').getTime();
        startUpdate(countDownDate);
    },[date])
    return(
        <>
        {loaded ?
        <>
        {distances > 0 ?
            <>
            <div className="classCountDown">
                <div className="countDownHeader">
                    <h3>H</h3>
                    <h3>M</h3>
                    <h3>S</h3>
                </div>
                <div className="countDownBody">
                    <h3>{hourn}</h3>
                    <h3>{minute}</h3>
                    <h3>{second}</h3>
                </div>
            </div>
            {/*<Table>
                <thead>
                    <tr>
                        <th>ORE</th>
                        <th>MIN</th>
                        <th>SEC</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{hourn}</td>
                        <td>{minute}</td>
                        <td>{second}</td>
                    </tr>
                </tbody>
            </Table>*/}
            </>
        :
            <div className="classCountDown">
                <h3>La partita sta per iniziare...</h3>
            </div>
        }
        </>
        :
            null
        }
        </>
    ); 
}
export default CountDown;