import React, { useEffect, useRef, useState } from 'react';
import { Carousel, Image, Col, Container, Row } from 'react-bootstrap';
function Sponsor() {
    const [active, setActive] = useState(0);
    const [arr, setArr] = useState([
        {id: 0, attivo: true},
        {id: 1, attivo: false},
        {id: 2, attivo: false},
        {id: 3, attivo: false},
    ])
    useEffect(() => {
        const attiva = (id) => {
            console.log('entro attiva', id)
            let trovato = false;
            const newArr = arr.map((item) => {
                if(item.id === id) {
                    if(item.attivo === true)
                        id+=1;
                    else
                        return {...item, attivo: true}
                    if(id >= arr.length) {
                        id = 0;
                        trovato = true;
                    }
                }
                return item;
            })
            if(trovato) {
                const altroArr = arr.map((item) => {
                    return {...item, attivo: false};
                })
                setArr(altroArr);
                console.log('altroarr', altroArr)
            } else {
                setArr(newArr)
                console.log(newArr, 'newarr');
            }
            console.log(id);
            setActive(id);
        }
        setTimeout(() => {
            let randomSlide = 0;
            randomSlide = Math.floor(Math.random() * 3);
            attiva(randomSlide);
        },10000);
    },[active]);
    return (
        <Container style={{marginBottom: '1rem'}} fluid>
            <Row>
                <Col xs={12}>
                    <Carousel controls={false} defaultActiveIndex={0} activeIndex={active} indicators={false} keyboard={false} fade >
                        <Carousel.Item>
                            <a target="_blank" href="https://realizzaonline.it/">
                                <Image
                                    className="d-block w-100"
                                    src="realizzaonline.png"
                                    alt="Realizza Online, sviluppa subito il tuo sito web e le tue webapp"
                                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <a target="_blank" href="https://beautyhairpalermo.it/">
                                <Image
                                    className="d-block w-100"
                                    src="beautyhair.png"
                                    alt="Beauty Hair di Giada Messina, Salone di Parrucchieria a Palermo in via Giovanni prati 32"
                                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <a target="_blank" href="https://monrealenews.it/">
                                <Image
                                    className="d-block w-100"
                                    src="monrealenews.png"
                                    alt="MonrealeNews.it, il primo quotidiano di Monreale"
                                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <a target="_blank" href="https://centrospaziovitale.it/">
                                <Image
                                    className="d-block w-100"
                                    src="spaziovitale.png"
                                    alt="Centro Spazio Vitale, Centro di Fisioterapia a Pioppo ( Monreale) in Via Provinciale 46"
                                />
                            </a>
                        </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    </Container >
    )
}

export default Sponsor;