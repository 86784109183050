import React, { useState, useEffect, useContext } from 'react';
import MenuNav from '../Global/MenuNav';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Risultati from '../Global/Risultati';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeRequest } from '../Global/makeRequest';
import Footer from '../Global/Footer';
import Sponsor from '../Sponsor/Sponsor';
import Girone from '../Global/Girone';
import { HandleLogout } from '../Global/Logout';
import { AuthContext } from '../../Auth/authContext';
function OpenGirone() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const [idgirone, setGirone] = useState(0);
    const [allteam, setAllTeam] = useState([]);
    const [partite, setPartite] = useState([]);
    const [user, setCurrentUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
            }
        })
        const nomegirone = queryParameters.get("id")
        console.log(nomegirone)
        if(nomegirone === null) {
            if (typeof window !== 'undefined') {
                navigate("/");
            }
        } else {
            makeRequest.post('/gironi/getfromname', {nomegirone})
            .then(res=>{
                setGirone(res.data[0].ID)
            });
        }
    },[])
    useEffect(() => {
        makeRequest.post('/partite/getfromgirone',{id: idgirone})
        .then(res => setPartite(res.data))
        makeRequest.post('/squadre/getall')
        .then(res=>setAllTeam(res.data));
    },[idgirone])
    return(
        <>
        <div className='containerPrimary'>
            <MenuNav active="/girone" user={user}/>
            <Sponsor/>
            <Container>
                <Row>
                    <Risultati allteam={allteam} match={partite}/>
                    <Col style={{textAlign: 'center'}} xs={12} md={6}>
                        <h1>Classifica Girone A</h1>
                        <Girone id={idgirone}/>
                    </Col>
                </Row>
            </Container>
            <Sponsor/>
            <Footer/>
        </div>
        </>
    );
}
export default OpenGirone;