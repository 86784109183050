import { Table } from "react-bootstrap";

export const YMD = (date) => {
  const val = date.split('-');
  return val[2]+'-'+val[1]+'-'+val[0];
}
export const calcolaClassifica = (gironeinfo, listasquadre, partite, id) => {
    const arr = [];
    gironeinfo.map((item) => {
        if(item.RifGirone === id) {
            for(var i = 0; i < listasquadre.length; i++) {
                const arr2 = {ID: listasquadre[i].ID, Nome: listasquadre[i].Nome, Logo: listasquadre[i].Logo, PG: 0, Vittorie: 0, N: 0, Perse: 0, RetiFatte: 0, RetiSubite:0, Punti: 0};
                if(item.RifSquadra === listasquadre[i].ID) {
                    for(var b = 0; b < partite.length; b++) {
                        if(item.RifGirone === partite[b].RifGirone) {
                            if(partite[b].Iniziata > 0) {
                                if(partite[b].RifSquadraCasa === listasquadre[i].ID) {
                                    arr2.PG++;
                                    arr2.RetiFatte+=partite[b].GoalCasa;
                                    arr2.RetiSubite+=partite[b].GoalFuori;
                                    if(partite[b].GoalCasa > partite[b].GoalFuori) {
                                        arr2.Vittorie++;
                                        arr2.Punti+=3;
                                    } else if(partite[b].GoalCasa === partite[b].GoalFuori) {
                                        arr2.N++;
                                        arr2.Punti+=1;
                                    } else {
                                        arr2.Perse++;
                                    }
                                } else if(partite[b].RifSquadraFuori === listasquadre[i].ID) {
                                    arr2.PG++;
                                    arr2.RetiFatte+=partite[b].GoalFuori;
                                    arr2.RetiSubite+=partite[b].GoalCasa;
                                    if(partite[b].GoalCasa > partite[b].GoalFuori) {
                                        arr2.Perse++;
                                    } else if(partite[b].GoalCasa === partite[b].GoalFuori) {
                                        arr2.N++;
                                        arr2.Punti+=1;
                                    } else {
                                        arr2.Vittorie++;
                                        arr2.Punti+=3;
                                    }
                                }
                            }
                        }
                    }
                    arr.push(arr2);
                }
            }
        }
    });
    arr.sort((a,b) => b.Punti - a.Punti || (b.GoalFatti-b.GoalSubiti)-(a.GoalFatti-a.GoalSubiti))
    return arr;
}