import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import {Container, Row, Col} from 'react-bootstrap'
import Skeleton from '@mui/material/Skeleton';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { makeRequest } from '../Global/makeRequest';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { uSocket } from '../../Socket/Socket';
import { YMD } from '../Functions/Functions';
import CountDown from '../Functions/CountDown';
import { useNavigate } from 'react-router-dom';

function MatchStats({id, showButton}) { 
    const [loaded, setLoaded] = useState(false);
    const [teamCasa, setTeamCasa] = useState('');
    const [teamOspite, setTeamOspite] = useState('');
    const [goalCasa, setGoalCasa] = useState(0);
    const [goalOspite, setGoalOspite] = useState(0);
    const [animateHome, setAnimateHome] = useState(false);
    const [animateAway, setAnimateAway] = useState(false);
    const [status, setStatus] = useState(true);
    const [halfTime, setHalfTime] = useState(false);
    const [tempo, setTempo] = useState('1°T');
    const [timer, setTimer] = useState('00:00')
    const [infoMatch, setInfoMatch] = useState([]);
    const navigate = useNavigate(); 
    const openMatch = () => {
        navigate('/openmatch?matchid='+id);
    }
    const openSquadra = (id) => {
        navigate('/squadre?team='+id);
    }
    useEffect(() => {
        if(id > 0) {
            uSocket?.emit('leaveRoom');
            makeRequest.post('/partite/getsingle',{id})
            .then(res=> {
                setInfoMatch(res.data);
                setStatus(res.data[0].Iniziata);
            })
            .catch(err=>console.log(err))
            uSocket?.emit('join', parseInt(id));
            console.log('entro join id', parseInt(id))
        } else {
            setLoaded(true);
        }
    },[id])
    useEffect(() => {
        if(infoMatch.length) {
            console.log(infoMatch);
            let idteam = infoMatch[0]?.RifSquadraCasa;
            console.log(idteam);
            makeRequest.post('/squadre/get', {idteam})
            .then(res => {
                setTeamCasa(res.data);
            })
            .catch(err => console.log(err))
            idteam = infoMatch[0]?.RifSquadraFuori;
            makeRequest.post('/squadre/get', {idteam})
            .then(res => {
                setTeamOspite(res.data);
                setLoaded(true);
            })
            .catch(err => console.log(err))
        }
    },[infoMatch])
    useEffect(() => {
        console.log('entro ma status?', status)
        if(status == 1) {
            uSocket?.on('timer', (data) => {
                //console.log('entro mai?',data)
                setTimer(data.time);
            })
            uSocket?.on('goalcasa', (data) => {
                setGoalCasa(data.value);
            });
            uSocket?.on('goalospite', (data) => {
                setGoalOspite(data.value);
            });
            uSocket?.on('animatehome', (data) => {
                setAnimateHome(data.animateHome);
            });
            uSocket?.on('animateaway', (data) => {
                setAnimateAway(data.animateAway);
            });
            uSocket?.on('cambiatempo', (data) => {
                setTempo(data.tempo);
            });
            uSocket?.on('changestatus', (data) => {
                setStatus(data.status);
            });
            uSocket?.on('intervallo', (data) => {
                setHalfTime(data.value);
            });

        }
    },[uSocket])
    useEffect(() => {
        if(status == 1) {
            console.log('qua ci entro ora?')
            makeRequest.post('/match/getgoalcasa')
            .then(res=> {
                setGoalCasa(res.data.goalvalue);
            })
            .catch(err=> {
                setGoalCasa(0);
                console.log('errore goal casa')
            })
            makeRequest.post('/match/getgoalfuori')
            .then(res=> {
                setGoalOspite(res.data.goalvalue);
            })
            .catch(err=> {
                setGoalOspite(0);
                console.log('errore goal ospite')
            })
            makeRequest.post('/match/getanimatehome')
            .then(res=> {
                setAnimateHome(res.data.animate);
            })
            .catch(err=> {
                setAnimateHome(false);
                console.log('errore goal animate home')
            })
            makeRequest.post('/match/getanimateaway')
            .then(res=> {
                setAnimateAway(res.data.animate);
            })
            .catch(err=> {
                setAnimateAway(false);
                console.log('errore goal animate away')
            })
            makeRequest.post('/match/gethalftime')
            .then(res=> {
                setTempo(res.data.tempo);
                setHalfTime(res.data.halftime);
            })
            .catch(err=>
                console.log(err)
            )
        }
    },[status])
    useEffect(() => {
        uSocket?.on('start', (data) => {
            console.log('entro qui?', data, id)
            if(id == data.idmatch) {
                uSocket?.emit('join', id);
                console.log('invece qui?')
                setStatus(prev => 1);
            }
        })
    },[uSocket, status, id])
    return(
        <Col className='containerMatch' xs={{span: 12}} md={{span: 8, offset: 2}}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="classStatus">
                        <>
                        {id > 0 ?
                            <>
                            {loaded ?
                            <>
                            {status == 1 ? 
                            <>

                                <FiberManualRecordRoundedIcon className='pulseIcon' fontSize='large' sx={{ color: 'red'}}/>
                                {!halfTime ? <h3 className='classTimer'>{tempo} {tempo != "RIGORI" ? timer : null}</h3> : <h3>Intervallo</h3>}
                            </> 

                            :
                            <>
                            {status == 2 ?
                            <>
                                <FiberManualRecordRoundedIcon fontSize='large' sx={{ color: 'gray' }}/>
                                <h3>Terminata</h3>
                            </>
                            :
                            <>
                                <FiberManualRecordRoundedIcon fontSize='large' sx={{ color: 'white' }}/>
                                <h3>Match: {YMD(infoMatch[0]?.Data)} - {infoMatch[0]?.Ora}</h3>
                            </>
                            }
                            </>
                            }
                        </>
                        :
                            <Skeleton sx={{bgcolor: 'gray'}} variant="rounded" width={300} height={50} />
                        }
                        </>
                        :
                            <h3>Nessuna partita in programma</h3>
                        }
                        </>
                        </div>
                    </Col>
                    {id > 0 ?
                    <>
                    <Col xs={4}>
                        <div className='classTeam'>
                            {loaded ? 
                                <>
                                <h1>{animateHome ? <SportsSoccerIcon className="goalIcon" fontSize='large'/> : null}{animateHome ?<span className='goalClass'>{teamCasa[0]?.Nome}</span>:<span>{teamCasa[0]?.Nome}</span>}</h1>
                                <img 
                                    className='iconMatch'
                                    src={teamCasa[0]?.Logo}
                                    onClick={() => openSquadra(teamCasa[0]?.ID)}
                                />
                                </>
                            :
                                <>
                                <Skeleton sx={{marginLeft: '3rem', bgcolor: 'gray'}} variant="rounded" width={100} height={30} />
                                <Skeleton sx={{marginLeft: '4rem', marginTop:'1rem', bgcolor: 'gray'}} variant="circular" width={60} height={60} />
                                </>
                            }
                        </div>
                    </Col>
                    <Col xs={4}>
                        {loaded ? 
                            <>
                            {status !== 0 ?
                                <div className="classRisultato">
                                    <h3>{animateHome ? <span className='goalClass'>{goalCasa}</span> : <span>{goalCasa}</span>} - {animateAway ? <span className='goalClass'>{goalOspite}</span> : <span>{goalOspite}</span>}</h3>
                                </div>
                            :
                                <CountDown date={infoMatch[0]?.Data} hour={infoMatch[0]?.Ora}/>
                            }
                            </>
                            :
                                <Skeleton sx={{marginLeft: '3rem', bgcolor: 'gray'}} variant="rounded" width={50} height={30} /> 
                        }
                    </Col>
                    <Col xs={4}>
                        <div className='classTeam'>
                            {loaded ?
                            <>
                                <h1>{animateAway ? <SportsSoccerIcon className="goalIcon" fontSize='large'/> : null}{animateAway ?<span className='goalClass'>{teamOspite[0]?.Nome}</span>:<span>{teamOspite[0]?.Nome}</span>}</h1>
                                <img 
                                    className='iconMatch'
                                    src={teamOspite[0]?.Logo}
                                    onClick={() => openSquadra(teamOspite[0]?.ID)}
                                />
                            </>
                            :
                            <>
                                <Skeleton sx={{marginLeft: '3rem', bgcolor: 'gray'}} variant="rounded" width={100} height={30} />
                                <Skeleton sx={{marginLeft:'4rem', marginTop: '1rem', bgcolor: 'gray'}} variant="circular" width={60} height={60} />
                            </>
                            }
                        </div>
                    </Col>
                    {showButton ? 
                    <Col style={{textAlign: 'right'}} xs={6}>
                        {loaded ? 
                            <Button className="mt-3" variant="contained" onClick={openMatch} sx={{color: 'white', backgroundColor: 'red'}} endIcon={<ChevronRightIcon/>}>Mostra Partita</Button>
                        :
                            <Skeleton sx={{marginLeft: '3rem', marginTop: '3rem', bgcolor: 'gray'}} variant="rounded" width={200} height={50} />
                        }
                    </Col>
                    : null}
                    </>
                    :
                        null
                    }
                </Row>
            </Container>
        </Col>
    );

}
export default MatchStats;