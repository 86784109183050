import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuNav from '../Global/MenuNav';
import { Container, Row, Col} from 'react-bootstrap';
import MatchStats from '../Global/MatchStats';
import Formazioni from '../Global/Formazioni';
import ReactPlayer from 'react-player';
import { makeRequest } from '../Global/makeRequest';
import Footer from '../Global/Footer';
import Sponsor from '../Sponsor/Sponsor';
import { AuthContext } from '../../Auth/authContext';
import { HandleLogout } from '../Global/Logout';
function Partite() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const [matchid, setMatch] = useState(0);
    const [partita, setPartita] = useState([]);
    const [user, setCurrentUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
            }
        })
        const match = queryParameters.get("matchid")
        if(match === null || isNaN(match)) {
            if (typeof window !== 'undefined') {
                navigate("/");
            }
        } else {
            setMatch(match);
        }
    },[]);
    useEffect(() => {
        if(matchid > 0) {
            makeRequest.post('/partite/getsingle',{id: matchid})
            .then(res=> setPartita(res.data[0]));
        }
    },[matchid])
    return(
        <>
        <div className='containerPrimary'>
            <MenuNav active="/" user={user}/>
            <Sponsor/>
            <Container>
                <Row>
                    {matchid > 0 && partita.URL ?
                        <div className='mb-3'>
                            <ReactPlayer width="100%" controls={true} playsinline={true} muted={false} url={partita.URL} />
                        </div>
                    : null}
                    {matchid > 0 ? <MatchStats id={matchid}/> : null }
                    {matchid > 0 ? <Formazioni matchid={matchid}/> : null }
                </Row>
            </Container>
            <Sponsor/>
            <Footer/>
        </div>
        </>
    );

};

export default Partite;