import { useEffect, useRef } from 'react';

function PrivacyScript() {
  const legalContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/93703ee0-d20c-45ad-8bc4-d3cf02b7bf08/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    legalContainerRef.current.appendChild(script);

    return () => {
      legalContainerRef.current.removeChild(script);
    };
  }, [legalContainerRef]);

  return (
      <div ref={legalContainerRef} className="legal__container" />
  );
}

export default PrivacyScript;