import React, { useEffect, useState } from 'react';
import { Col, Table, Image} from 'react-bootstrap';
import { makeRequest } from './makeRequest';
import { calcolaClassifica } from '../Functions/Functions';
import { useNavigate } from 'react-router-dom';

function Girone({id}) {
    const [squadre, setSquadre] = useState([]);
    const [gironi, setGironi] = useState([]);
    const [partite, setPartite] = useState([]);
    const [classifica, setClassifica] = useState([]);
    const navigate = useNavigate();
    const openTeam = (id) => {
        navigate('/squadre?team='+id);
    }
    useEffect(() => {
        makeRequest.post('/squadre/getsquadreingirone')
        .then(res=>{
            setGironi(res.data);
        })
        makeRequest.post('/squadre/getall')
        .then(res=>{
            setSquadre(res.data);
        })
        makeRequest.post('/partite/getfromgirone',{id})
        .then(res=>{
            setPartite(res.data);
        })
    },[id])
    useEffect(() => {
        setClassifica(calcolaClassifica(gironi, squadre, partite, id));
    },[gironi, squadre, partite])
    return(
        <Col xs={12}>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th style={{textAlign: 'left'}}>Squadra</th>
                        <th>PG</th>
                        <th>V</th>
                        <th>N</th>
                        <th>P</th>
                        <th>R</th>
                        <th>DR</th>
                        <th>P</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        const arr = [];
                        let count = 1;
                        classifica.map((item) => {
                            arr.push(<tr>
                                <td>{count}</td>
                                <td style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => openTeam(item.ID)}><Image src={item.Logo} className='logosmall'/>{item.Nome}</td>
                                <td>{item.PG}</td>
                                <td>{item.Vittorie}</td>
                                <td>{item.N}</td>
                                <td>{item.Perse}</td>
                                <td>{item.RetiFatte}:{item.RetiSubite}</td>
                                <td>{item.RetiFatte-item.RetiSubite}</td>
                                <td>{item.Punti}</td>
                            </tr>)
                            count++;
                        });
                        return arr;
                    })()}
                </tbody>
            </Table>
        </Col>
    )
}
export default Girone;