import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
function Footer() {
    return(
        <Container className="Footer" fluid>
            <Row>
                <Col className="d-block" xs={12} md={4}>
                    <a href="https://realizzaonline.it/">
                        <Image
                            height="100px"
                            width="100px"
                            src="logorealizza.png"
                            alt="Logo RealizzaOnline.it"
                        />
                    </a>
                    <p>Sviluppato interamente da <a href="https://realizzaonline.it/"><b>Realizza Online</b></a></p>
                </Col>
                <Col className="d-block" xs={12} md={4}>
                    <br/>
                    <p>Torneo dei Quartieri e delle Frazioni di Monreale</p>
                </Col>
                <Col className="d-block" xs={12} md={4}>
                    <br/>
                    <a href="/privacy">Privacy & Cookie Policy</a>
                </Col>
            </Row>
        </Container>
    );
}
export default Footer;