import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Table } from 'react-bootstrap';
import { uSocket } from '../../Socket/Socket';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Skeleton from '@mui/material/Skeleton';
import SquareRounded from '@mui/icons-material/SquareRounded'
import { makeRequest } from './makeRequest';
function Formazioni({matchid}) {
    const [partita, setPartita] = useState([]);
    const [squadre, setSquadre] = useState([]);
    const [formazioni, setFormazioni] = useState([]);
    const [sostituzioni, setSostituzioni] = useState([]);
    const [calciatori, setCalciatori] = useState([]);
    const [eventi, setEventi] = useState([]);
    const [goal, setGoal] = useState([]);
    const [cartellini, setCartellini] = useState([]);
    const [update, setUpdate] = useState(0);
    const [status, setStatus] = useState(false);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        uSocket?.on('formazioniupdate', (data) => {
            console.log('entro?')
            setUpdate(prev=> prev+1);
        })
        uSocket?.on('goalcasa', (data) => {
            setUpdate(prev=> prev+1);
            console.log('entro goal casa');
        })
        uSocket?.on('goalospite', (data) => {
            setUpdate(prev=> prev+1);
        })
    },[update, uSocket])
    useEffect(() => {
        console.log('join matchid',parseInt(matchid));
        uSocket?.emit('join', parseInt(matchid));
        makeRequest.post('/partite/getsingle', {id: matchid})
        .then(res => {
            setPartita(res.data[0]);
            setStatus(res.data[0].Iniziata);
        })
        makeRequest.post('/formazioni/get', {matchid})
        .then(res=> {
            console.log(res.data);
            setFormazioni(res.data);
        });
        makeRequest.post('/formazioni/getsostituzioni', {matchid})
        .then(res=> {
            setSostituzioni(res.data);
            /*setSostituzioni([{RifEntrante: 2, RifUscente: 1}, {RifEntrante: 3, RifUscente: 4}])*/
        })
        makeRequest.post('/eventi/getmatch', {matchid})
        .then(res=> {
            setEventi(res.data);
        })
        makeRequest.post('/eventi/getgoal', {matchid})
        .then(res=> {
            setGoal(res.data);
            /*setGoal([
                {ID: 1, RifCalciatore: 1},
                {ID: 2, RifCalciatore: 2},
                {ID: 3, RifCalciatore: 1}
            ]);*/
        })
        makeRequest.post('/eventi/getcartellini', {matchid})
        .then(res=> {
            /*setCartellini([{RifCalciatore: 1, Tipo: 0},{RifCalciatore: 2, Tipo: 1},{RifCalciatore: 1, Tipo: 0}])*/
            setCartellini(res.data);
        })
    },[matchid, update])
    useEffect(() => {
        if(partita) {
            makeRequest.post('/calciatori/getmatch', {idteam: [partita.RifSquadraCasa, partita.RifSquadraFuori]})
            .then(res=> {
                console.log(res.data);
                setCalciatori(res.data);
            })
            makeRequest.post('/squadre/getmatch', {idteam: [partita.RifSquadraCasa, partita.RifSquadraFuori]})
            .then(res=> {
                setSquadre(res.data);
                setTimeout(() => setLoaded(true),1000);
            })
        }
    },[partita]);
    const getCalciatore = (id) => {
        let calciatore = undefined;
        calciatori.map((item) => {
            if(item.ID === id)
                calciatore = item;
        })
        return calciatore;
    }
    const getTeamLogo = (id) => {
        let logo = '';
        squadre.map((item) => {
            if(item.ID === id)
                logo = item.Logo;
        })
        return logo;
    }
    const getTeamNome = (id) => {
        let nome = '';
        squadre.map((item) => {
            if(item.ID === id) 
                nome = item.Nome;
        })
        return nome;
    }
    return(
        <Container className='containerFormazioni'>
            <h1>Formazioni</h1>
            <Row>
                <Col className='teamHome' xs={6}>
                    <div className="teamInfo">
                        {loaded ? 
                            <>
                            <Image className='mediumlogo' src={getTeamLogo(partita.RifSquadraCasa)}/>
                            <h3>{getTeamNome(partita.RifSquadraCasa)}</h3>
                            </>
                        :
                            <Skeleton sx={{margin:'0 auto', bgcolor: 'gray'}} variant="circular" width={80} height={80} />
                        }
                    </div>
                    <hr/>
                    {loaded ?
                    <>
                    {(() => {
                        const arr = [];
                        let count = 0;
                        formazioni.map((item) => {
                            if(item.RifSquadra === partita.RifSquadraFuori) {
                                if(item.StatoIniziale === 1) {
                                    count++;
                                    const arr2 = [];
                                    const calciatore = getCalciatore(item.RifCalciatore);
                                    let countcartellini = 0;
                                    for(var i = 0; i < cartellini.length; i++) {
                                        if(cartellini[i].RifCalciatore === item.RifCalciatore) {
                                            if(cartellini[i].Tipo === 0) {
                                                if(countcartellini === 1)
                                                    arr2.push(<SquareRounded sx={{marginLeft: '10px', color:'red', border: '1px solid yellow', borderRadius: '5px'}}/>);
                                                else
                                                    arr2.push(<SquareRounded sx={{marginLeft: '10px', color:'yellow'}}/>);
                                                countcartellini++;
                                            } else 
                                                arr2.push(<SquareRounded sx={{marginLeft: '10px', color:'red'}}/>);
                                        }
                                    }
                                    for(var i = 0; i < goal.length; i++) {
                                        console.log(goal[i]);
                                        if(goal[i].RifCalciatore === item.RifCalciatore) {
                                            console.log('entro goal', item.RifCalciatore);
                                            arr2.push(<SportsSoccerIcon sx={{marginLeft: '10px'}}/>);
                                        }
                                    }
                                    for(var i = 0; i < sostituzioni.length; i++) {
                                        if(sostituzioni[i].RifUscente === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginLeft: '10px', color: 'red'}}/>);
                                        } else if(sostituzioni[i].RifEntrante === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginLeft: '10px', color: '#32CD32'}}/>);
                                        }
                                    }
                                    if(count%2 == 0) {
                                        arr.push(<div className='calciatoriDivHome'><span>{calciatore?.Numero} {calciatore?.Nome[0]}. {calciatore?.Cognome}{arr2}</span></div>);
                                    } else { 
                                        arr.push(<div className='calciatoriDivHome2'><span>{calciatore?.Numero} {calciatore?.Nome[0]}. {calciatore?.Cognome}{arr2}</span></div>);
                                    }
                                }
                            }
                        });
                        return arr;
                    })()}
                    </>
                    : 
                        <>
                        {(() => {
                            const arr = [];
                            for(var i = 0; i < 11; i++) {
                                if(i%2 == 0) {
                                    arr.push(<div className='calciatoriDivHome'><Skeleton sx={{bgcolor: 'gray'}} variant="rounded" width={200} height={25}/></div>);
                                } else { 
                                    arr.push(<div className='calciatoriDivHome2'><Skeleton sx={{bgcolor: 'gray'}} variant="rounded" width={200} height={25}/></div>);
                                }
                            }
                            return arr;
                        })()}
                        </>
                    }
                </Col>
                <Col className='teamAway' xs={6}>
                    <div className="teamInfo">
                        {loaded ?
                            <>
                            <Image className='mediumlogo' src={getTeamLogo(partita.RifSquadraFuori)}/>
                            <h3>{getTeamNome(partita.RifSquadraFuori)}</h3>
                            </>
                        :
                            <Skeleton sx={{margin:'0 auto', bgcolor: 'gray'}} variant="circular" width={80} height={80} /> 
                        }
                    </div>
                    <hr/>
                    {loaded ? <>
                    {(() => {
                        const arr = [];
                        let count = 0;
                        formazioni.map((item) => {
                            if(item.RifSquadra === partita.RifSquadraFuori) {
                                if(item.StatoIniziale === 1) {
                                    count++;
                                    const arr2 = [];
                                    const calciatore = getCalciatore(item.RifCalciatore);
                                    for(var i = 0; i < sostituzioni.length; i++) {
                                        if(sostituzioni[i].RifUscente === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginRight: '10px', color: 'red'}}/>);
                                        } else if(sostituzioni[i].RifEntrante === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginRight: '10px', color: '#32CD32'}}/>);
                                        }
                                    }
                                    for(var i = 0; i < goal.length; i++) {
                                        if(goal[i].RifCalciatore === item.RifCalciatore) {
                                            arr2.push(<SportsSoccerIcon sx={{marginRight: '10px'}}/>);
                                        }
                                    }
                                    let countcartellini = 0;
                                    for(var i = cartellini.length-1; i>=0; i--) {
                                        if(cartellini[i].RifCalciatore === item.RifCalciatore) {
                                            if(cartellini[i].Tipo === 0) {
                                                if(countcartellini === 1) {
                                                    arr2.pop();
                                                    arr2.push(<SquareRounded sx={{marginRight: '10px', color:'red', border: '1px solid yellow', borderRadius: '5px'}}/>);
                                                    arr2.push(<SquareRounded sx={{marginRight: '10px', color:'yellow'}}/>);   
                                                } else
                                                    arr2.push(<SquareRounded sx={{marginRight: '10px', color:'yellow'}}/>);
                                                countcartellini++;
                                            } else 
                                                arr2.push(<SquareRounded sx={{marginRight: '10px', color:'red'}}/>);
                                        }
                                    }
                                    if(count%2 == 0) {
                                        arr.push(<div className='calciatoriDivAway'><span>{arr2}{calciatore?.Cognome} {calciatore?.Nome[0]}. {calciatore?.Numero}</span></div>)
                                    } else {
                                        arr.push(<div className='calciatoriDivAway2'><span>{arr2}{calciatore?.Cognome} {calciatore?.Nome[0]}. {calciatore?.Numero}</span></div>) 
                                    }
                                }
                            }
                        });
                        return arr;
                    })()}
                    </>
                    :
                    <>
                    {(() => {
                        const arr = [];
                        for(var i = 0; i < 11; i++) {
                            if(i%2 == 0) {
                                arr.push(<div style={{textAlign: 'right'}} className='calciatoriDivAway'><Skeleton sx={{bgcolor: 'gray'}} variant="rounded" width={200} height={25}/></div>);
                            } else { 
                                arr.push(<div style={{textAlign: 'right'}} className='calciatoriDivAway2'><Skeleton sx={{bgcolor: 'gray'}} variant="rounded" width={200} height={25}/></div>);
                            }
                        }
                        return arr;
                    })()}
                    </>
                    }
                </Col>
            </Row>
            <>
            {loaded ? <>
            <h1>Panchina</h1>
            <Row>
                <Col className="teamHome" xs={6}>
                    {(() => {
                        const arr = [];
                        let count = 0;
                        formazioni.map((item) => {
                            if(item.RifSquadra === partita.RifSquadraCasa) {
                                if(item.StatoIniziale === 0) {
                                    count++;
                                    const arr2 = [];
                                    const calciatore = getCalciatore(item.RifCalciatore);
                                    let countcartellini = 0;
                                    for(var i = 0; i < cartellini.length; i++) {
                                        if(cartellini[i].RifCalciatore === item.RifCalciatore) {
                                            if(cartellini[i].Tipo === 0) {
                                                if(countcartellini === 1)
                                                    arr2.push(<SquareRounded sx={{marginLeft: '10px', color:'red', border: '1px solid yellow', borderRadius: '5px'}}/>);
                                                else
                                                    arr2.push(<SquareRounded sx={{marginLeft: '10px', color:'yellow'}}/>);
                                                countcartellini++;
                                            } else 
                                                arr2.push(<SquareRounded sx={{marginLeft: '10px', color:'red'}}/>);
                                        }
                                    }
                                    for(var i = 0; i < goal.length; i++) {
                                        console.log(goal[i]);
                                        if(goal[i].RifCalciatore === item.RifCalciatore) {
                                            console.log('entro goal', item.RifCalciatore);
                                            arr2.push(<SportsSoccerIcon sx={{marginLeft: '10px'}}/>);
                                        }
                                    }
                                    for(var i = 0; i < sostituzioni.length; i++) {
                                        if(sostituzioni[i].RifUscente === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginLeft: '10px', color: 'red'}}/>);
                                        } else if(sostituzioni[i].RifEntrante === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginLeft: '10px', color: '#32CD32'}}/>);
                                        }
                                    }
                                    if(count%2 == 0) {
                                        arr.push(<div className='calciatoriDivHome'><span>{calciatore?.Numero} {calciatore?.Nome[0]}. {calciatore?.Cognome}{arr2}</span></div>);
                                    } else { 
                                        arr.push(<div className='calciatoriDivHome2'><span>{calciatore?.Numero} {calciatore?.Nome[0]}. {calciatore?.Cognome}{arr2}</span></div>);
                                    }
                                }
                            }
                        });
                        return arr;
                    })()}
                </Col>
                <Col className="teamAway" xs={6}>
                {(() => {
                        const arr = [];
                        let count = 0;
                        formazioni.map((item) => {
                            if(item.RifSquadra === partita.RifSquadraFuori) {
                                if(item.StatoIniziale === 0) {
                                    count++;
                                    const arr2 = [];
                                    const calciatore = getCalciatore(item.RifCalciatore);
                                    for(var i = 0; i < sostituzioni.length; i++) {
                                        if(sostituzioni[i].RifUscente === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginRight: '10px', color: 'red'}}/>);
                                        } else if(sostituzioni[i].RifEntrante === item.RifCalciatore) {
                                            arr2.push(<SwapHorizIcon sx={{marginRight: '10px', color: '#32CD32'}}/>);
                                        }
                                    }
                                    for(var i = 0; i < goal.length; i++) {
                                        if(goal[i].RifCalciatore === item.RifCalciatore) {
                                            arr2.push(<SportsSoccerIcon sx={{marginRight: '10px'}}/>);
                                        }
                                    }
                                    let countcartellini = 0;
                                    for(var i = cartellini.length-1; i >=0; i--) {
                                        if(cartellini[i].RifCalciatore === item.RifCalciatore) {
                                            if(cartellini[i].Tipo === 0) {
                                                if(countcartellini === 1) {
                                                    arr2.pop();
                                                    arr2.push(<SquareRounded sx={{marginRight: '10px', color:'red', border: '1px solid yellow', borderRadius: '5px'}}/>);
                                                    arr2.push(<SquareRounded sx={{marginRight: '10px', color:'yellow'}}/>);   
                                                } else
                                                    arr2.push(<SquareRounded sx={{marginRight: '10px', color:'yellow'}}/>);
                                                countcartellini++;
                                            } else {
                                                arr2.push(<SquareRounded sx={{marginRight: '10px', color:'red'}}/>);
                                            }
                                        }
                                    }
                                    if(count%2 == 0) {
                                        arr.push(<div className='calciatoriDivAway'><span>{arr2}{calciatore?.Cognome} {calciatore?.Nome[0]}. {calciatore?.Numero}</span></div>)
                                    } else {
                                        arr.push(<div className='calciatoriDivAway2'><span>{arr2}{calciatore?.Cognome} {calciatore?.Nome[0]}. {calciatore?.Numero}</span></div>) 
                                    }
                                }
                            }
                        });
                        return arr;
                    })()}
                </Col>
            </Row>
            {status ? <>
            <h1>Diretta Testuale</h1>
            <Row>
                <Col xs={12} className="textEventi">
                    {(() => {
                        const arr = [];
                        let count = 0;
                        eventi.map((item) => {
                            count++;
                            const sfondo = count%2;
                            switch(item.Tipo) {
                                case 0: {
                                    for(var i = 0; i < goal.length; i++) {
                                        if(goal[i].ID === item.RifGoal) {
                                            const calciatore = getCalciatore(item.RifCalciatore);
                                            const nomesquadra = getTeamNome(calciatore?.RifSquadra);
                                            let assist = goal[i].RifAssist;
                                            let assistman = null;
                                            if(assist > 0)
                                                assistman = getCalciatore(assist);
                                            return arr.push(<div className={sfondo == 0 ? 'eventiOdd' : 'eventiEven'}>
                                                <p><b>{item.Minuto}'</b></p>
                                                <p><b>GOAL</b>! Ha segnato per <b>{nomesquadra}</b>, con il numero <b>(#{calciatore?.Numero})</b>, <b>{calciatore?.Nome} {calciatore?.Cognome}</b>{assist > 0 ? <span> su assist di <b>{assistman?.Nome[0]}. {assistman?.Cognome}</b> (<b>#{assistman?.Numero}</b>)!</span> : '!'}</p>
                                            </div>);
                                        }
                                    }
                                    break;
                                }
                                case 1: {
                                    let countcartellini = 0;
                                    for(var i = 0; i < cartellini.length; i++) {
                                        if(cartellini[i].RifCalciatore === item.RifCalciatore) {
                                            if(cartellini[i].Tipo === 0) {
                                                countcartellini++;
                                            }
                                        }
                                        if(cartellini[i].ID === item.RifCartellino) {
                                            const calciatore = getCalciatore(item.RifCalciatore);
                                            const nomesquadra = getTeamNome(calciatore?.RifSquadra);
                                            let tipocartellino = 0;
                                            if(cartellini[i].Tipo === 1)
                                                tipocartellino = 1;
                                            else if(cartellini[i].Tipo === 0) {
                                                if(countcartellini === 2) {
                                                    tipocartellino = 2;
                                                }
                                            }
                                            return arr.push(<div className={sfondo == 0 ? 'eventiOdd' : 'eventiEven'}>
                                                <p><b>{item.Minuto}'</b></p>
                                                <p>L'arbitro estrae il cartellino! {tipocartellino == 1 ? <span><b>Espulso</b>!</span> : <> {tipocartellino == 2 ? <span><b>Doppia Ammonizione ed espulsione</b>!</span> : <span><b>Ammonizione</b>!</span>}</>} <b>{calciatore?.Nome} {calciatore?.Cognome} (#{calciatore?.Numero})</b> di <b>{nomesquadra}</b> ha ricevuto un {tipocartellino == 2 ? 'altro' : null} cartellino {tipocartellino == 1? <span><b>rosso</b>!</span>: <span><b>giallo</b>!</span>}</p>
                                                </div>);
                                        }
                                    }
                                    break;
                                }
                                case 2: {
                                    for(var i = 0; i < sostituzioni.length; i++) {
                                        if(sostituzioni[i].ID === item.RifSostituzione) {
                                            const entrante = getCalciatore(sostituzioni[i].RifEntrante);
                                            const uscente = getCalciatore(sostituzioni[i].RifUscente);
                                            const nomesquadra = getTeamNome(sostituzioni[i].RifSquadra);
                                            return arr.push(<div className={sfondo == 0 ? 'eventiOdd' : 'eventiEven'}>
                                                <p><b>{item.Minuto}'</b></p>
                                                <p>Sostituzione! <b>{nomesquadra}</b> effettua un cambio. Esce con il numero <b>(#{uscente?.Numero}) {uscente?.Nome} {uscente?.Cognome}</b> per far spazio al numero <b>(#{entrante?.Numero}) {entrante?.Nome} {entrante?.Cognome}</b>.</p>
                                                </div>)
                                        }
                                    }
                                    break;
                                }
                                case 3: {
                                    return arr.push(<div className={sfondo == 0 ? 'eventiOdd' : 'eventiEven'}>
                                        <p><b>{item.Minuto}'</b></p>
                                        <p><b>Intervallo</b>.</p>
                                        </div>)     
                                }
                            }
                        })
                        count++;
                        const sfondo = count%2;
                        arr.push(<div className={sfondo == 0 ? 'eventiOdd' : 'eventiEven'}>
                            <p><b>1'</b></p>
                            <p>La partita è <b>iniziata!</b> L'arbitro ha dato il via al match tra <b>{getTeamNome(partita.RifSquadraCasa)}</b> e <b>{getTeamNome(partita.RifSquadraFuori)}</b>.</p>
                        </div>)
                        return arr;
                    })()}
                </Col>
            </Row>
            </> : null}
            </>
            : null} 
            </>
        </Container>
    )
}

export default Formazioni;