import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { makeRequest } from "../Global/makeRequest";
import MenuNav from "../Global/MenuNav";
import { useNavigate } from "react-router-dom";
import Footer from "../Global/Footer";
import Sponsor from "../Sponsor/Sponsor";
import { AuthContext } from "../../Auth/authContext";
import { HandleLogout } from "../Global/Logout";

function Team() {
    const [lista, setLista] = useState([]);
    const [user, setCurrentUser] = useState(null);
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
            }
        })
        makeRequest.post('/squadre/getall')
        .then(res=>setLista(res.data))
    },[])
    const openSquadra = (id) => {
        navigate('/squadre?team='+id);
    }
    return(
        <>
        <div className='containerPrimary'>
            <MenuNav active="/team" user={user}/>
            <Sponsor/>
            <Container>
                <Row>
                    <h1>Team del Torneo</h1>
                    <hr/>
                    {(() => {
                        const arr=[];
                        lista.map((item) => {
                            arr.push(<Col className='teamInfo teamInfoBorder justify-content-center' xs={12} md={4}>
                                <Image
                                    className="iconMatch"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => openSquadra(item.ID)}
                                    src={item.Logo}/>
                                <h1>{item.Nome}</h1>
                            </Col>)
                        });
                        return arr;
                    })()}
                </Row>
            </Container>
            <Sponsor/>
            <Footer/>
        </div>
        </>
    );
}
export default Team;