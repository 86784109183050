import React, { useContext, useEffect, useState } from 'react';
import { makeRequest } from '../Global/makeRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuNav from '../Global/MenuNav';
import { Col, Container, Row, Image, Table } from 'react-bootstrap';
import Girone from '../Global/Girone.js';
import Risultati from '../Global/Risultati.js';
import Footer from '../Global/Footer.js';
import Sponsor from '../Sponsor/Sponsor.js';
import { AuthContext } from '../../Auth/authContext.js';
import { HandleLogout } from '../Global/Logout.js';

function Squadre() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const [team, setTeam] = useState(0);
    const [partite, setPartite] = useState([]);
    const [squadra, setSquadra] = useState([]);
    const [allteam, setAllTeam] = useState([]);
    const [giocatori, setGiocatori] = useState([]);
    const [dirigenti, setDirigenti] = useState([]);
    const [girone, setGirone] = useState(0);
    const navigate = useNavigate();
    const [user, setCurrentUser] = useState(null);
    const { logout } = useContext(AuthContext);

    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
            }
        })
        const idteam = queryParameters.get("team")
        if(idteam === null || isNaN(idteam)) {
            if (typeof window !== 'undefined') {
                navigate("/");
            }
        } else {
            console.log('ci entro?')
            makeRequest.post('/squadre/get',{idteam})
            .then(res=> {
                setTeam(idteam);
                setSquadra(res.data[0]);
            })
            makeRequest.post('/squadre/getall')
            .then(res=> {
                setAllTeam(res.data);
            })
            makeRequest.post('/partite/getfromteam', {idteam})
            .then(res=>setPartite(res.data))
            makeRequest.post('/calciatori/getfromteam', {idteam})
            .then(res=>setGiocatori(res.data))
            makeRequest.post('/dirigenti/getfromteam', {idteam})
            .then(res=>setDirigenti(res.data));
            makeRequest.post('/squadre/getsquadragirone', {idteam}) 
            .then(res=>{
                setGirone(res.data[0].RifGirone)
            });
        }
    },[])
    return (
        <>
        {team ? 
            <div className='containerPrimary'>
                <MenuNav active="/team" user={user}/>
                <Sponsor/>
                <Container>
                    <Row>
                        <Col className="teamInfo" xs={12} md={6}>
                            <Image className='iconMatch' src={squadra?.Logo}/>
                            <h1>{squadra?.Nome}</h1>
                        </Col>
                        <Risultati allteam={allteam} match={partite} team={team} squadra={squadra}/>
                        <Col xs={12}>
                            <h1>Team</h1>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nome</th>
                                        <th>Cognome</th>
                                        <th>Ruolo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(()=> {
                                        const arr = [];
                                        let result = giocatori.filter(item => item.Ruolo == "Portiere")
                                        result.map((item) => {
                                            arr.push(<tr>
                                                <td>{item.Numero}</td>
                                                <td>{item.Nome}</td>
                                                <td>{item.Cognome}</td>
                                                <td>{item.Ruolo}</td>
                                                </tr>)
                                        })
                                        result = giocatori.filter(item => item.Ruolo == "Difensore")
                                        result.map((item) => {
                                            arr.push(<tr>
                                                <td>{item.Numero}</td>
                                                <td>{item.Nome}</td>
                                                <td>{item.Cognome}</td>
                                                <td>{item.Ruolo}</td>
                                                </tr>)
                                        })
                                        result = giocatori.filter(item => item.Ruolo == "Centrocampista")
                                        result.map((item) => {
                                            arr.push(<tr>
                                                <td>{item.Numero}</td>
                                                <td>{item.Nome}</td>
                                                <td>{item.Cognome}</td>
                                                <td>{item.Ruolo}</td>
                                                </tr>)
                                        })
                                        result = giocatori.filter(item => item.Ruolo == "Attaccante")
                                        result.map((item) => {
                                            arr.push(<tr>
                                                <td>{item.Numero}</td>
                                                <td>{item.Nome}</td>
                                                <td>{item.Cognome}</td>
                                                <td>{item.Ruolo}</td>
                                            </tr>)
                                        })
                                        return arr;
                                    })()}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={12}>
                            <h1>Dirigenti</h1>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Cognome</th>
                                        <th>Ruolo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(()=> {
                                        const arr = [];
                                        dirigenti.map((item) => {
                                            arr.push(<tr>
                                                <td>{item.Nome}</td>
                                                <td>{item.Cognome}</td>
                                                <td>{item.Ruolo}</td>
                                                </tr>
                                            )
                                        })
                                        return arr;
                                    })()}
                                </tbody>
                            </Table>
                        </Col>
                        <Girone id={girone}/>
                    </Row>
                </Container>
                <Sponsor/>
                <Footer/>
            </div>
        : null}
        </>
    );
}

export default Squadre;