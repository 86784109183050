import React, {useState, useEffect, useContext}  from 'react';
import { makeRequest } from '../Global/makeRequest';
import MenuNav from '../Global/MenuNav';
import { Container, Row, Col, Table} from 'react-bootstrap';
import Button from '@mui/material/Button';
import MatchStats from '../Global/MatchStats';
import Spinner from 'react-bootstrap/Spinner';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SquareRounded from '@mui/icons-material/SquareRounded';
import Footer from '../Global/Footer';
import { HandleLogout } from '../Global/Logout';
import { AuthContext } from '../../Auth/authContext';
import { useNavigate } from 'react-router-dom';
function Admin() {
    const [user, setCurrentUser] = useState(null);
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);
    const [matchid, setMatchID] = useState(0);
    const [calciatori, setCalciatori] = useState([]);
    const [status, setStatus] = useState(false);
    const [formazioni, setFormazioni] = useState([])
    const [eventi, setEventi] = useState([]);
    const [listagoal, setListaGoal] = useState([]);
    const [listacartellini, setListaCartellini] = useState([]);
    const [selectedFormazioni, setSelectedFormazioni] = useState(false);
    const [selectedSostituzioni, setSelectedSostituzioni] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState(false);
    const [cartellinos, setCartellino] = useState({RifCalciatore: 0, TipoCartellino: 0, RifSquadra: 0, Clicked: false});
    const [goals, setGoal] = useState({RifSquadra: 0, RifCalciatore: 0, RifAssist: 0, Clicked: false});
    const [selectedCartellini, setSelectedCartellini] = useState(false);
    const [sostituzioni, setSostituzioni] = useState([]);
    const [formazionisost, setFormazioniSost] = useState([])
    const [squadracasa, setSquadraHome] = useState(0);
    const [squadraospite, setSquadraAway] = useState(0);  
    const [selectedHome, setSelectedHome] = useState(0);
    const [selectedAway, setSelectedAway] = useState(0);
    const [squadraHomeName, setSquadraHomeName] = useState('');
    const [squadraAwayName, setSquadraAwayName] = useState('');
    const [saving, setSaving] = useState(false);
    const [sostituzioniHome, setSostituzioniHome] = useState(0);
    const [sostituzioniAway, setSostituzioniAway] = useState(0);
    const [clickedSostituto, setClickedSostituto] = useState({RifSquadra: 0, RifUscente: 0, Clicked: false})
    const saveGoal = () => {
        makeRequest.post('/match/getminuto')
        .then(res=> {
            const minuto = res.data.minuto;
            const giocatore = goals.RifCalciatore;
            const tipo = 0;
            const goal = [
                matchid, giocatore, minuto
            ]
            const assist = goals.RifAssist;
            makeRequest.post('/eventi/insertgoal', {goal, assist, tipo})
            .then(res => {
                makeRequest.post('/eventi/getgoal',{matchid})
                .then(res=> {
                    setListaGoal(res.data);
                    setSelectedGoal(false);
                    if(goals.RifSquadra === squadracasa)
                        goalhome();
                    else
                        goalaway();
                    setGoal({RifCalciatore: 0, RifAssist: 0, RifSquadra: 0, Clicked: false});
                })
            })
        });
    }
    const sostituisciCalciatore = (idcalciatore) => {
        makeRequest.post('/match/getminuto')
        .then(res=> {
            const minuto = res.data.minuto;
            const uscente = clickedSostituto.RifUscente;
            const squadra = clickedSostituto.RifSquadra;
            const tipo = 2;
            const sostituzione = [
                matchid, uscente, idcalciatore, squadra, minuto
            ]
            const eventosostituzione = [
                matchid, idcalciatore, tipo, minuto
            ]
            makeRequest.post('/eventi/insertsostituzione',{sostituzione, eventosostituzione})
            .then(res=> {
                makeRequest.post('/formazioni/getsostituzioni',{matchid})
                .then(res=> {
                    setSostituzioni(res.data);
                    setClickedSostituto({RifSquadra: 0, RifUscente: 0, Clicked: false});
                    makeRequest.post('/match/updateformazioni',{matchid: parseInt(matchid)})
                })
            })
        })
    }
    useEffect(() => {
        if(matchid > 0) {
            makeRequest.post('/eventi/getmatch',{matchid})
            .then(res=> {
                console.log('entro', res.data)
                setEventi(res.data);
            })
        }
    },[sostituzioni, listacartellini, listagoal])
    const setSelected = (id) => {
        console.log(id, 'id clickato');
        const arr = calciatori.map((item) => {
            if(item.ID === id) {
                console.log(id, 'id entrato');
                if(item.RifSquadra === squadracasa) {
                    if(item.Selected) {
                        return {...item, Selected: !item.Selected};
                    } else {
                        if(selectedHome >= 11)
                            return {...item, Panchina: !item.Panchina};
                        else 
                            return {...item, Selected: !item.Selected};
                    }
                } else if(item.RifSquadra === squadraospite) {
                    if(item.Selected) {
                        return {...item, Selected: !item.Selected};
                    } else {
                        if(selectedAway >= 11)
                            return {...item, Panchina: !item.Panchina};
                        else 
                            return {...item, Selected: !item.Selected};
                    }
                }
            }
            return item;
        })
        getSelected(arr);
        setCalciatori(arr);
    }
    const getSelected = (lista) => {
        let countcasa = 0;
        let countospite = 0;
        lista.map((item) => {
            if(item.RifSquadra === squadracasa) {
                if(item.Selected)
                    countcasa++;
            } else if(item.RifSquadra === squadraospite) {
                if(item.Selected)
                    countospite++;
            }
        });
        setSelectedHome(countcasa);
        setSelectedAway(countospite);
    }
    const salvaFormazioni = async() => {
        await makeRequest.post('/formazioni/delete', {matchid})
        return await Promise.all(
            calciatori.map(async (item) => {
                if(item.Selected) {
                    await makeRequest.post('/formazioni/save',{matchid: matchid, calciatore: item.ID, squadra: item.RifSquadra, stato: 1, capitano: item.Capitano})
                } else if(item.Panchina) {
                    await makeRequest.post('/formazioni/save',{matchid: matchid, calciatore: item.ID, squadra: item.RifSquadra, stato: 0, capitano: item.Capitano})
                }
                return item;
            })
        );
    }
    const saveFormazioni = async() => {
        setSaving(true);
        const salvato = await salvaFormazioni();
        setSaving(false);
        setSelectedFormazioni(false);
        setFormazioniSost(calciatori);
        makeRequest.post('/match/updateformazioni',{matchid: parseInt(matchid)});
    }
    const saveCartellino = (tipocartellino) => {
        makeRequest.post('/match/getminuto')
        .then(res=> {
            const minuto = res.data.minuto;
            const giocatore = cartellinos.RifCalciatore;
            const tipo = 1;
            const cartellino = [
                matchid, giocatore, minuto
            ]
            makeRequest.post('/eventi/insertcartellino', {cartellino, tipocartellino, tipo})
            .then(res => {
                makeRequest.post('/eventi/getcartellini',{matchid})
                .then(res=> {
                    setListaCartellini(res.data);
                    setSelectedCartellini(false);
                    setCartellino({RifCalciatore: 0, TipoCartellino: 0, RifSquadra: 0, Clicked: false});
                })
                makeRequest.post('/match/updateformazioni',{matchid: parseInt(matchid)})
            })
        });
    }
    useEffect(() => {
        const updateSelected = () => {
            const arr = createSelected(calciatori);
            console.log(formazioni, arr);
            const newArr = arr.map((item) => {
                for(var i = 0; i < formazioni.length; i++) {
                    if(item.ID === formazioni[i].RifCalciatore) {
                        if(formazioni[i].StatoIniziale === 1) {
                            return {...item, Selected: true}
                        } else {
                            return {...item, Panchina: true}
                        }
                    }
                }
                return item;
            })
            setCalciatori(newArr);
            getSelected(newArr);
        }
        if(formazioni.length)
            updateSelected();
    },[formazioni])
    useEffect(() => {
        const calcolaSostituzioni = () => {
            let counthome = 0;
            let countaway = 0;
            for(var i = 0; i < sostituzioni.length; i++) {
                if(sostituzioni[i].RifSquadra === squadracasa)
                    counthome++;
                else if(sostituzioni[i].RifSquadra === squadraospite)
                    countaway++;
            }
            console.log(counthome, countaway, sostituzioni);
            setSostituzioniHome(counthome);
            setSostituzioniAway(countaway);
        }
        const newArr = formazioni.map((item) => {
            for(var i = 0; i < sostituzioni.length; i++) {
                if(sostituzioni[i].RifUscente === item.RifCalciatore) {
                    return {...item, StatoIniziale: 2}
                } else if(sostituzioni[i].RifEntrante === item.RifCalciatore) {
                    return {...item, StatoIniziale: 1}
                }
            }
            return item;
        })
        setFormazioniSost(newArr);
        if(sostituzioni.length)
            calcolaSostituzioni();
    },[sostituzioni])
    const createSelected = (lista) => {
        const arr = lista.map((item) => {
            return {...item, Selected: false, Panchina: false, Capitano: false};
        })
        return arr;
    }
    const startMatch = () => {
        makeRequest.post('/match/start/', {matchid})
        .then(res => {
            makeRequest.post('/partite/start', {matchid})
            .then(res => {
                makeRequest.post('/match/updateformazioni', {matchid})
                setStatus(true);
            })
            .catch(err => {
                console.log('errore avvio');
            });
            console.log('avviato');
        });
    }
    const stop = () => {
        makeRequest.post('/match/stop/')
        .then(res => {
            console.log('fermato');
        });        
    }
    const resetTimer = () => {
        makeRequest.post('/match/end')
        .then(res => {
            setStatus(false);
            makeRequest.post('/partite/end', {matchid})
            .then(res=> {
                makeRequest.post('/match/updateformazioni', {matchid})
            })
            console.log('end');
        })
    }
    const HALF = () => {
        makeRequest.post('/match/halftime')
        .then(res => {
            console.log('halftime');
        })
        makeRequest.post('/match/getminuto')
        .then(res=> {
            const minuto = res.data.minuto;
            makeRequest.post('/eventi/intervallo', {matchid, minuto})
        });
    }
    const goalhome = () => {
        makeRequest.post('/match/goalcasa')
        .then(res => {
            makeRequest.post('/partite/goalcasa',{matchid})
            .then(res=> {
                makeRequest.post('/match/updateformazioni',{matchid})
            })
            console.log('goal assegnato casa');
        })        
    }
    const goalaway = () => {
        makeRequest.post('/match/goalfuori')
        .then(res => {
            makeRequest.post('/partite/goalfuori',{matchid})
            .then(res=> {
                makeRequest.post('/match/updateformazioni',{matchid})
            })
            console.log('goal assegnato ospite');
        })        
    }
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
                console.log('entro logout')
            }
        })
        makeRequest.get('/partite/nextmatch')
        .then(res => { 
            const idmatch = res.data[0].ID;
            setMatchID(res.data[0].ID)
            makeRequest.post('/match/getstatus')
            .then(res => {
                setStatus(res.data.status);
            })
            if(parseInt(res.data[0].ID) > 0) {
                makeRequest.post('/partite/getsingle',{id: res.data[0].ID})
                .then(res=> {
                    const partita = res.data[0];
                    setSquadraHome(partita.RifSquadraCasa);
                    setSquadraAway(partita.RifSquadraFuori);
                    makeRequest.post('/squadre/get',{idteam: partita.RifSquadraCasa})
                    .then(res=>{
                        setSquadraHomeName(res.data[0].Nome);
                    })
                    makeRequest.post('/squadre/get',{idteam: partita.RifSquadraFuori})
                    .then(res=>{
                        setSquadraAwayName(res.data[0].Nome);
                    })
                    makeRequest.post('/calciatori/getmatch', {idteam: [partita.RifSquadraCasa, partita.RifSquadraFuori]})
                    .then(res=> {
                        setCalciatori(res.data);
                        makeRequest.post('/formazioni/get', {matchid: idmatch})
                        .then(res=> {
                            setFormazioni(res.data);
                            const formazionipersos = res.data;
                            makeRequest.post('/formazioni/getsostituzioni',{matchid: idmatch})
                            .then(res=> {
                                setSostituzioni(res.data);
                            })
                            makeRequest.post('/eventi/getcartellini', {matchid: idmatch})
                            .then(res=> {
                                setListaCartellini(res.data);
                            })
                            makeRequest.post('/eventi/getgoal', {matchid: idmatch})
                            .then(res=> {
                                setListaGoal(res.data);
                            })
                        });
                    })
                });
            }
        })
        .catch(err => console.log(err));

    },[])
    const getCalciatore = (id) => {
        const arr = [];
        let calciatore = undefined;
        let color = 'white';
        calciatori.map((item) => {
            if(item.ID === id) {
                calciatore = item;
                for(var i = 0; i < listacartellini.length; i++) {
                    if(item.ID === listacartellini[i].RifCalciatore) {
                        if(listacartellini[i].Tipo)
                            color = 'red';
                        else
                            color = 'yellow';
                    }
                }
            }
        })
        arr.push(calciatore, color);
        return arr;
    }
    const actions = [
        { icon: <PlayArrowIcon />, name: 'Start', azione: startMatch },
        { icon: <PauseIcon />, name: 'Pausa', azione: stop },
        { icon: <SyncAltIcon />, name: 'Intervallo', azione: HALF },
        { icon: <RestartAltIcon />, name: 'Fine Partita', azione: resetTimer },
    ];
    return (
        <>
            <div className='containerPrimary'>
                <MenuNav active="/admin" user={user}/>
                <Container>
                    <Row>
                        <MatchStats id={matchid}/>
                    </Row>
                    {matchid > 0 ? <>
                        {!selectedFormazioni && !selectedSostituzioni && !selectedCartellini ? <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        icon={<SpeedDialIcon />}
                        >
                        {actions.map((action) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.azione}
                            />
                        ))}
                    </SpeedDial>
                    : null}
                    <Col xs={12}>
                        {selectedFormazioni ?
                            <>
                                <Button onClick={() => setSelectedFormazioni(false)} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                <div className="teamHome">
                                    <h3 style={{color: 'white'}}>Titolari selezionati: <b>{selectedHome}/11</b> - {squadraHomeName}</h3>
                                    {(() => {
                                        const arr = [];
                                        calciatori.map((item) => {
                                            if(item.RifSquadra === squadracasa) {
                                                arr.push(<Button onClick={() => setSelected(item.ID)} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{item.Numero} {item.Nome[0]}. {item.Cognome}</Button>)
                                            }
                                        });
                                        return arr;
                                    })()}
                                </div>
                                <div className="teamAway">
                                    <h3 style={{color: 'white'}}>Titolari selezionati: <b>{selectedAway}/11</b> - {squadraAwayName}</h3>
                                    {(() => {
                                        const arr = [];
                                        calciatori.map((item) => {
                                            if(item.RifSquadra === squadraospite) {
                                                arr.push(<Button onClick={() => setSelected(item.ID)} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{item.Numero} {item.Nome[0]}. {item.Cognome}</Button>)
                                            }
                                        });
                                        return arr;
                                    })()}
                                </div>
                                {!saving ?
                                    <Button onClick={saveFormazioni} variant="contained" color="success" endIcon={<SaveIcon/>}>Salva</Button>
                                :
                                    <Button startIcon={<Spinner size="sm" animation="border" role="status"/>} variant="outlined" color='success'>
                                        Salvo
                                    </Button>
                                }
                            </>
                        :
                            <>
                            {selectedSostituzioni ? 
                                    <>
                                        {!clickedSostituto.Clicked ?
                                            <>
                                            <Button onClick={() => { setSelectedSostituzioni(false); setClickedSostituto({RifSquadra: 0, RifUscente: 0, Clicked: false})}} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                            <div className="teamHome">
                                                <h3 style={{color: 'white'}}>{squadraHomeName} - Sostituzioni effettuate: <b>{sostituzioniHome}</b></h3>
                                                {(() => {
                                                    const arr = [];
                                                    formazionisost.map((item) => {
                                                        for(var i = 0; i < calciatori.length; i++) {
                                                            if(item.RifSquadra === squadracasa) {
                                                                if(item.StatoIniziale === 1) {
                                                                    if(item.RifCalciatore === calciatori[i].ID) {
                                                                        arr.push(<Button onClick={() => setClickedSostituto({...clickedSostituto, RifUscente: item.RifCalciatore, RifSquadra: item.RifSquadra, Clicked: true})} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    })
                                                    return arr;
                                                })()}
                                            </div>
                                            <div className="teamAway">
                                                <h3 style={{color: 'white'}}>{squadraAwayName} - Sostituzioni effettuate: <b>{sostituzioniAway}</b></h3>
                                                {(() => {
                                                    const arr = [];
                                                    formazionisost.map((item) => {
                                                        for(var i = 0; i < calciatori.length; i++) {
                                                            if(item.RifSquadra === squadraospite) {
                                                                if(item.StatoIniziale === 1) {
                                                                    if(item.RifCalciatore === calciatori[i].ID) {
                                                                        arr.push(<Button onClick={() => setClickedSostituto({...clickedSostituto, RifUscente: item.RifCalciatore, RifSquadra: item.RifSquadra, Clicked: true})} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    })
                                                    return arr;
                                                })()}
                                            </div>
                                            </> 
                                        :
                                            <>
                                                <Button onClick={() => { setSelectedSostituzioni(false); setClickedSostituto({RifSquadra: 0, RifUscente: 0, Clicked: false})}} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                                <div className={clickedSostituto.RifSquadra === squadracasa ? "teamHome" : "teamAway"}>
                                                    <h3 style={{color: 'white'}}>{clickedSostituto.RifSquadra === squadracasa ? squadraHomeName : squadraAwayName} - Sostituzioni effettuate: <b>{clickedSostituto.RifSquadra === squadracasa ? sostituzioniHome : sostituzioniAway}</b></h3>
                                                    {(() => {
                                                        const arr = [];
                                                        let trovato = false;
                                                        formazionisost.map((item) => {
                                                            for(var i = 0; i < calciatori.length; i++) {
                                                                if(item.RifSquadra === clickedSostituto.RifSquadra) {
                                                                    if(item.StatoIniziale === 0) {
                                                                        if(item.RifCalciatore === calciatori[i].ID) {
                                                                            trovato = true;
                                                                            arr.push(<Button onClick={() => sostituisciCalciatore(item.RifCalciatore)} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                        }
                                                                    }
                                                                }

                                                            }
                                                        });
                                                        if(!trovato)
                                                            arr.push(<h3 style={{color: 'white'}}>Nessun elemento rimasto in panchina</h3>)
                                                        return arr;
                                                    })()}
                                                </div>

                                            </>
                                        }
                                    </>
                                :
                                    <>
                                    {selectedCartellini ? 
                                        <>
                                            {!cartellinos.Clicked ?
                                                <>
                                                <Button onClick={() => { setSelectedCartellini(false); setCartellino({RifCalciatore: 0, TipoCartellino: 0, RifSquadra: 0, Clicked: false})}} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                                <div className="teamHome">
                                                    <h3 style={{color: 'white'}}>{squadraHomeName}</h3>
                                                    {(() => {
                                                        const arr = [];
                                                        formazioni.map((item) => {
                                                            let trovato = 0;
                                                            let cartellinocolor = 'primary';
                                                            for(var i = 0; i < calciatori.length; i++) {
                                                                if(item.RifSquadra === squadracasa) {
                                                                    if(item.RifCalciatore === calciatori[i].ID) {
                                                                        for(var b = 0; b < listacartellini.length; b++) {
                                                                            if(listacartellini[b].RifCalciatore === item.RifCalciatore) {
                                                                                if(listacartellini[b].Tipo) {
                                                                                    cartellinocolor = 'red';
                                                                                    trovato = 2;
                                                                                } else {
                                                                                    trovato++;
                                                                                    cartellinocolor = 'yellow';
                                                                                    if(trovato == 2)
                                                                                        cartellinocolor = 'red';
                                                                                }
                                                                            }
                                                                        }
                                                                        arr.push(<Button onClick={() => { if(trovato < 2) setCartellino({...cartellinos, RifCalciatore: item.RifCalciatore, RifSquadra: item.RifSquadra, Clicked: true})}} sx={{color: cartellinocolor}} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                    }
                                                                }
                                                            }
                                                        })
                                                        return arr;
                                                    })()}
                                                </div>
                                                <div className="teamAway">
                                                    <h3 style={{color: 'white'}}>{squadraAwayName}</h3>
                                                    {(() => {
                                                        const arr = [];
                                                        formazioni.map((item) => {
                                                            let trovato = 0;
                                                            let cartellinocolor = 'primary';
                                                            for(var i = 0; i < calciatori.length; i++) {
                                                                if(item.RifSquadra === squadraospite) {
                                                                    if(item.RifCalciatore === calciatori[i].ID) {
                                                                        for(var b = 0; b < listacartellini.length; b++) {
                                                                            if(listacartellini[b].RifCalciatore === item.RifCalciatore) {
                                                                                if(listacartellini[b].Tipo) {
                                                                                    cartellinocolor = 'red';
                                                                                    trovato = 2;
                                                                                } else {
                                                                                    trovato++;
                                                                                    cartellinocolor = 'yellow';
                                                                                    if(trovato == 2)
                                                                                        cartellinocolor = 'red';
                                                                                }
                                                                            }
                                                                        }
                                                                        arr.push(<Button onClick={() => { if(trovato < 2) setCartellino({...cartellinos, RifCalciatore: item.RifCalciatore, RifSquadra: item.RifSquadra, Clicked: true})}} sx={{color: cartellinocolor}} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                    }
                                                                }
                                                            }
                                                        })
                                                        return arr;
                                                    })()}
                                                </div> 
                                                </>
                                            :
                                               <>
                                                <Button onClick={() => { setSelectedCartellini(false); setCartellino({RifCalciatore: 0, TipoCartellino: 0, RifSquadra: 0, Clicked: false})}} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                                <div className={cartellinos.RifSquadra === squadracasa ? "teamHome" : "teamAway"}>
                                                    {(() => { 
                                                        const arr = [];
                                                        const val = getCalciatore(cartellinos.RifCalciatore) 
                                                        console.log(val);
                                                        arr.push(<span style={{color: val[1]}}>#{val[0].Numero} {val[0].Nome} {val[0].Cognome}</span>)
                                                        return (<h3 style={{color: 'white'}}>{cartellinos.RifSquadra === squadracasa ? squadraHomeName : squadraAwayName} - <b>{arr}</b></h3>);
                                                    })()}
                                                    <Button onClick={() => saveCartellino(0)} variant="outlined" sx={{color: 'yellow'}}><SquareRounded/></Button>
                                                    <Button onClick={() => saveCartellino(1)} variant="outlined" sx={{color: 'red'}}><SquareRounded/></Button>
                                                </div> 
                                                </>
                                            }                                    
                                        </>
                                    :
                                        <>
                                            {selectedGoal ? 
                                                <>
                                                    {!goals.Clicked ?
                                                    <>
                                                        <Button onClick={() => { setSelectedGoal(false); setGoal({RifSquadra: 0, RifCalciatore: 0, RifAssist: 0, Clicked: false})}} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                                        <div className="teamHome">
                                                            <h3 style={{color: 'white'}}><b>{squadraHomeName}</b></h3>
                                                            {(() => {
                                                                const arr = [];
                                                                formazionisost.map((item) => {
                                                                    for(var i = 0; i < calciatori.length; i++) {
                                                                        if(item.RifSquadra === squadracasa) {
                                                                            if(item.StatoIniziale === 1) {
                                                                                if(item.RifCalciatore === calciatori[i].ID) {
                                                                                    arr.push(<Button onClick={() => setGoal({...goals, RifSquadra: item.RifSquadra, RifCalciatore: item.RifCalciatore, Clicked: true})} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                                return arr;
                                                            })()}
                                                        </div>
                                                        <div className="teamAway">
                                                            <h3 style={{color: 'white'}}><b>{squadraAwayName}</b></h3>
                                                            {(() => {
                                                                const arr = [];
                                                                formazionisost.map((item) => {
                                                                    for(var i = 0; i < calciatori.length; i++) {
                                                                        if(item.RifSquadra === squadraospite) {
                                                                            if(item.StatoIniziale === 1) {
                                                                                if(item.RifCalciatore === calciatori[i].ID) {
                                                                                    arr.push(<Button onClick={() => setGoal({...goals, RifSquadra: item.RifSquadra, RifCalciatore: item.RifCalciatore, Clicked: true})} color={(item.Selected) ? 'success' : item.Panchina ? 'error' : 'primary'} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                                return arr;
                                                            })()}
                                                        </div>
                                                    </>
                                                    :
                                                        <>
                                                        <Button onClick={() => { setSelectedGoal(false); setGoal({RifCalciatore: 0, RifAssist: 0, RifSquadra: 0, Clicked: false})}} variant="outlined" color="error" sx={{marginBottom: '1rem'}}><CloseIcon/></Button>
                                                        <div className={goals.RifSquadra === squadracasa ? "teamHome" : "teamAway"}>
                                                            {(() => { 
                                                                const arr = [];
                                                                const val = getCalciatore(goals.RifCalciatore) 
                                                                console.log(val);
                                                                arr.push(<h3 style={{color: 'white'}}>Goal - #{val[0].Numero} {val[0].Nome} {val[0].Cognome}</h3>)
                                                                arr.push(<p style={{color: 'white'}}>Seleziona l'eventuale Assist o Salva</p>);
                                                                formazionisost.map((item) => {
                                                                    for(var i = 0; i < calciatori.length; i++) {
                                                                        if(item.RifSquadra === squadraospite) {
                                                                            if(item.StatoIniziale === 1) {
                                                                                if(item.RifCalciatore === calciatori[i].ID) {
                                                                                    if(item.RifCalciatore !== goals.RifCalciatore)
                                                                                        arr.push(<Button onClick={() => { 
                                                                                            if(goals.RifAssist !== item.RifCalciatore) 
                                                                                                setGoal({...goals, RifAssist: item.RifCalciatore}) 
                                                                                            else 
                                                                                                setGoal({...goals, RifAssist: 0}) }} color={(goals.RifAssist === item.RifCalciatore) ? 'success' : 'primary'} variant="outlined">#{calciatori[i].Numero} {calciatori[i].Nome[0]}. {calciatori[i].Cognome}</Button>)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                                return arr;
                                                            })()}
                                                        </div> 
                                                        <Button onClick={saveGoal} variant="contained" color="success" endIcon={<SaveIcon/>}>Salva</Button>
                                                        </>
                                                    }
                                                    
                                                </>
                                            :
                                                <>
                                                    {!status ? 
                                                    <Button onClick={() => setSelectedFormazioni(true)} variant="outlined" color="primary">
                                                        Formazioni
                                                    </Button>
                                                    : null}
                                                    {status ?
                                                    <Button onClick={() => setSelectedGoal(true)} variant="outlined" color="primary">
                                                        Goal
                                                    </Button>
                                                    : null}
                                                    {status ? 
                                                    <Button onClick={() => setSelectedCartellini(true)} variant="outlined" color="primary">
                                                        Cartellini
                                                    </Button>
                                                    : null}
                                                    {status ?
                                                    <Button onClick={() => setSelectedSostituzioni(true)} variant="outlined" color="primary">
                                                        Sostituzioni
                                                    </Button>
                                                    : null}
                                                </>
                                            }
                                        </>
                                    }
                                    </>
                                }
                            </>
                        }
                    </Col>
                    <Col xs={12}>
                        <h1>Eventi</h1>
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Minuto</th>
                                    <th>Tipo</th>
                                    <th>Calciatore</th>
                                    <th>Squadra</th>
                                    <th>Modifica</th>
                                    <th>Elimina</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    const arr = [];
                                    let count = 0;
                                    console.log('qua ci arrivo giusto?')
                                    eventi.map((item) => {
                                        console.log('ma ci entro mai?')
                                        count++;
                                        const calciatore = getCalciatore(item.RifCalciatore);
                                        arr.push(<tr>
                                            <td>{count}</td>
                                            <td>{item.Minuto}'</td>
                                            <td>{item.Tipo == 0 ? 'Goal' : <> {item.Tipo == 1 ? 'Cartellino' : 'Sostituzione'}</>}</td>
                                            <td>{calciatore[0]?.Nome} {calciatore[0]?.Cognome} (#{calciatore[0]?.Numero})</td>
                                            <td>{squadracasa === calciatore[0]?.RifSquadra ? squadraHomeName : squadraAwayName}</td>
                                            <td>Icona Edit</td>
                                            <td>Icona Elimina</td>
                                        </tr>)
                                    })
                                    return arr;
                                })()}
                            </tbody>
                        </Table>
                    </Col>
                    </> : null}
                </Container>
                <Footer/>
            </div>
        </>
    );
}

export default Admin;