import React, {useState, useEffect} from 'react';
import { Col, Table, Image, Button} from 'react-bootstrap';
import { uSocket } from '../../Socket/Socket';
import { makeRequest } from './makeRequest';
import { YMD } from '../Functions/Functions';
import { useNavigate } from 'react-router-dom';
function Risultati({match, allteam, team, squadra, props}) {
    const [startsocket, setSocket] = useState(false);
    const [timer, setTimer] = useState('00:00');
    const [partite, setPartite] = useState([]);
    const matchid = 5;
    const navigate = useNavigate();
    const openMatch = (id) => {
        navigate('/openmatch?matchid='+id);
    }
    /*const startMatch = () => {
        makeRequest.post('/match/start/', {matchid})
        .then(res => {
            makeRequest.post('/partite/start', {matchid})
            .then(res => {
                console.log('updated');
            })
            .catch(err => {
                console.log('errore avvio');
            });
            console.log('avviato');
        });
    }
    const resetTimer = () => {
        makeRequest.post('/match/end')
        .then(res => {
            makeRequest.post('/partite/reset', {matchid})
            .then(res => {
                console.log('reset');
            })
            .catch(err => {
                console.log('errore reset');
            });
        })
    }*/
    const getTeamLogo = (id) => {
        let logo = '';
        allteam.map((item) => {
            if(item.ID === id)
                logo = item.Logo;
        })
        return logo;
    }
    const getTeamNome = (id) => {
        let nome = '';
        allteam.map((item) => {
            if(item.ID === id) 
                nome = item.Nome;
        })
        return nome;
    }
    useEffect(() => {
        setPartite(match);
    },[match])
    useEffect(() => {
        console.log(partite);
        partite.map((item) => {
            if(item.Iniziata == 1) {
                uSocket?.emit('leaveRoom');
                uSocket?.emit('join', item.ID);
                setSocket(true);
            }
            return false;
        })
    },[partite])
    const editInfo = (name,value, idmatch = 0) => {
        console.log(name, value, partite);
        const newArr = partite.map((item) => {
            if(item.Iniziata == 1) {
                switch(name) {
                    case 'GoalCasa': {
                        return {...item, GoalCasa: value};
                    }
                    case 'GoalFuori': {
                        return {...item, GoalFuori: value};
                    }
                    case 'AnimateHome': {
                        return {...item, AnimateHome: value};
                    }
                    case 'AnimateAway': {
                        return {...item, AnimateAway: value};
                    }
                    case 'Tempo': {
                        return {...item, Tempo: value};
                    }
                    case 'HalfTime': {
                        return {...item, Intervallo: value};
                    }
                }
            } else {
                if(item.ID === idmatch) {
                    if(name == 'Iniziata') {
                        return {...item, Iniziata: value};
                    }
                }
            }
            return item;
        })
        console.log(newArr);
        setPartite(newArr);
    }
    const updateStatus = (idmatch) => {
        console.log('entro qui');
        editInfo('Iniziata',1,idmatch);
    }
    useEffect(() => {
        console.log('entro');
        if(startsocket) {
        makeRequest.post('/match/getgoalcasa')
        .then(res=> {
            editInfo('GoalCasa', res.data.goalvalue);
        })
        .catch(err=> {
            editInfo('GoalCasa', 0);
            console.log('errore goal casa')
        })
        makeRequest.post('/match/getgoalfuori')
        .then(res=> {
            editInfo('GoalFuori',res.data.goalvalue);
        })
        .catch(err=> {
            editInfo('GoalFuori',0);
            console.log('errore goal ospite')
        })
        makeRequest.post('/match/getanimatehome')
        .then(res=> {
            editInfo('AnimateHome', res.data.animate);
        })
        .catch(err=> {
            editInfo('AnimateHome', false);
            console.log('errore goal animate home')
        })
        makeRequest.post('/match/getanimateaway')
        .then(res=> {
            editInfo('AnimateAway', res.data.animate);
        })
        .catch(err=> {
            editInfo('AnimateAway', false);
            console.log('errore goal animate away')
        })
        makeRequest.post('/match/gethalftime')
        .then(res=> {
            console.log('entro tempo', res.data.tempo);
            editInfo('Tempo',res.data.tempo);
        })
        .catch(err=> {
            editInfo('Tempo','1°T');
            console.log(err)
        })
        makeRequest.post('/match/gethalftime')
        .then(res=> {
            editInfo('HalfTime', res.data.halftime);
        })
    }
    },[startsocket])
    useEffect(() => {
        console.log('non entro?')
        if(startsocket) {
            uSocket?.on('timer', (data) => {
                setTimer(data.time);
            })
            uSocket?.on('goalcasa', (data) => {
                editInfo('GoalCasa', data.value);
            });
            uSocket?.on('goalospite', (data) => {
                editInfo('GoalFuori', data.value);
            });
            uSocket?.on('animatehome', (data) => {
                editInfo('AnimateHome',data.animateHome);
            });
            uSocket?.on('animateaway', (data) => {
                editInfo('AnimateAway', data.animateAway);
            });
            uSocket?.on('cambiatempo', (data) => {
                editInfo('Tempo', data.tempo);
            });
            uSocket?.on('changestatus', (data) => {
                editInfo('Iniziata', data.status);
            });  
            uSocket?.on('intervallo', (data) => {
                editInfo('HalfTime', data.value);
            });
        }
        uSocket?.on('start', (data) => {
            updateStatus(data.idmatch);
            editInfo('HalfTime', false);
        })
    },[startsocket, partite])
    return(
        <Col style={{marginTop: '3rem', textAlign: (props?.align ? props?.align : 'inherit')}} xs={12} md={(props?.md ? props?.md : 6)}>
            <h1>Match {props?.label}</h1>
            <Table responsive className="risultati">
                <tbody>
                    {(() => {
                        const arr = [];
                        partite.map((item) => {
                            if(team && squadra) {
                                for(var i = 0; i < allteam.length; i++) {
                                    if(item.RifSquadraCasa == team && item.RifSquadraFuori == allteam[i].ID) {
                                        arr.push(<tr onClick={() => openMatch(item.ID)}>
                                            {item.Iniziata == 1 ?
                                                <td className='firsttd'>{item.Intervallo ? <span className='redcolor'>Intervallo</span> : <span className='redcolor'>{item.Tempo}<br/>{item.Tempo !== "RIGORI" ? timer : null}}</span>}</td>
                                            :
                                                <td>{YMD(item.Data)} {item.Ora}</td>
                                            }
                                            <td className='lefta'><Image className='logosmall' src={squadra.Logo}/><b>{squadra.Nome}</b><br/><Image className='logosmall' src={allteam[i].Logo}/>{allteam[i].Nome}</td>
                                            {item.Iniziata == 2 ?
                                                <td>{item.GoalCasa}<br/>{item.GoalFuori}</td>
                                            :
                                                <>
                                                {!item.Iniziata ?
                                                    <td>-<br/>-</td>    
                                                :
                                                    <td><span className='redcolor'>{item.GoalCasa}</span><br/><span className='redcolor'>{item.GoalFuori}</span></td>
                                                }
                                                </>
                                            }
                                            {item.Iniziata == 2 ?
                                                <td>
                                                    {item.GoalCasa < item.GoalFuori ? <span className="sconfitta">P</span> 
                                                    : <> {item.GoalCasa == item.GoalFuori ? <span className="pareggio">N</span> :
                                                        <span className="vittoria">V</span>} </> }
                                                </td>
                                                : 
                                                <td></td>
                                            }
                                        </tr>)
                                    } else if(item.RifSquadraFuori == team && item.RifSquadraCasa == allteam[i].ID) {
                                        arr.push(<tr onClick={() => openMatch(item.ID)}>
                                            {item.Iniziata == 1 ?
                                                <td className='firsttd'>{item.Intervallo ? <span className='redcolor'>Intervallo</span> : <span className='redcolor'>{item.Tempo}<br/>{item.Tempo !== "RIGORI" ? timer : null}</span>}</td>
                                            :
                                                <td>{YMD(item.Data)} {item.Ora}</td>
                                            }
                                            <td className='lefta'><Image className='logosmall' src={allteam[i].Logo}/>{allteam[i].Nome}<br/><Image className='logosmall' src={squadra.Logo}/><b>{squadra.Nome}</b></td>
                                            {item.Iniziata == 2 ?
                                                <td>{item.GoalCasa}<br/>{item.GoalFuori}</td>
                                            :
                                                <>
                                                {!item.Iniziata ?
                                                    <td>-<br/>-</td>    
                                                :
                                                    <td><span className={`${item.AnimateHome ? 'goalClass goalIcon':'redcolor'}`}>{item.GoalCasa}</span><br/><span className={`${item.AnimateAway ? 'goalClass goalIcon':'redcolor'}`}>{item.GoalFuori}</span></td>
                                                }
                                                </>
                                            }
                                            {item.Iniziata == 2 ?
                                                <td>
                                                    {item.GoalCasa < item.GoalFuori ? <span className="vittoria">V</span> 
                                                    : <> {item.GoalCasa == item.GoalFuori ? <span className="pareggio">N</span> :
                                                        <span className="sconfitta">P</span>} </> }
                                                </td>
                                                :
                                                    <>
                                                    {item.Iniziata == 1 ? 
                                                        <>
                                                            <td>{item.AnimateHome ? <span className="goalClass">GOAL!</span> : null} 
                                                            {item.AnimateAway ? <>
                                                                <br/><span className="goalClass">GOAL!</span></> : null}
                                                            </td>
                                                        </>
                                                    : <td></td>}
                                                </>
                                            }
                                        </tr>)
                                    }
                                }
                            } else {
                                arr.push(<tr onClick={() => openMatch(item.ID)}>
                                    {item.Iniziata == 1 ?
                                        <td className='firsttd'>{item.Intervallo ? <span className='redcolor'>Intervallo</span> : <span className='redcolor'>{item.Tempo}<br/>{timer}</span>}</td>
                                    :
                                        <td>{YMD(item.Data)} {item.Ora}</td>
                                    }
                                    <td className='lefta'><Image className='logosmall' src={getTeamLogo(item.RifSquadraCasa)}/>{getTeamNome(item.RifSquadraCasa)}<br/><Image className='logosmall' src={getTeamLogo(item.RifSquadraFuori)}/>{getTeamNome(item.RifSquadraFuori)}</td>
                                    {item.Iniziata == 2 ?
                                        <td>{item.GoalCasa}<br/>{item.GoalFuori}</td>
                                    :
                                        <>
                                        {!item.Iniziata ?
                                            <td>-<br/>-</td>    
                                        :
                                            <td><span className={`${item.AnimateHome ? 'goalClass goalIcon':'redcolor'}`}>{item.GoalCasa}</span><br/><span className={`${item.AnimateAway ? 'goalClass goalIcon':'redcolor'}`}>{item.GoalFuori}</span></td>
                                        }
                                        </>
                                    }
                                    {item.Iniziata == 1 ? 
                                        <>
                                            <td>{item.AnimateHome ? <span className="goalClass">GOAL!</span> : null} 
                                                {item.AnimateAway ? <>
                                                <br/><span className="goalClass">GOAL!</span></> : null}
                                            </td>
                                        </>
                                    : <td></td>}
                                </tr>)             
                            }
                        })
                        return arr;
                    })()}
                </tbody>
            </Table>
        </Col>
    );
}
export default Risultati;