import React, {useContext} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import './App.css';
import Login from './Component/Login/Login';
import Home from './Component/Homepage/Home';
import Partite from './Component/Partite/Partite';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from './Auth/authContext';
import Squadre from './Component/Squadre/Squadre';
import Admin from './Component/Admin/Admin';
import Calciatore from './Component/Calciatore/Calciatore';
import Team from './Component/Squadre/Team';
import OpenGirone from './Component/Girone/OpenGirone';
import Privacy from './Component/Privacy/Privacy';
function App() {
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/login" element={currentUser !== null ? <Navigate to="/admin"/>:<Login/>}></Route>
          <Route path="/openmatch" element={<Partite/>}></Route>
          <Route path="/squadre" element={<Squadre/>}></Route>
          <Route path="/opengirone" element={<OpenGirone/>}></Route>
          <Route path="/team" element={<Team/>}></Route>
          <Route path="/admin" element={currentUser === null ? <Navigate to="/login"/>:<Admin/>}></Route>
          <Route path="/privacy" element={<Privacy/>}></Route> 
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
