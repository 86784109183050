import React, { useState, useContext, useEffect } from 'react';
import MenuNav from '../Global/MenuNav';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../Global/Logout';
import { AuthContext } from '../../Auth/authContext';
import Sponsor from '../Sponsor/Sponsor';
import Footer from '../Global/Footer';
import { makeRequest } from '../Global/makeRequest';
import PrivacyScript from './PrivacyScript.js';

function Privacy() {    
    const [user, setCurrentUser] = useState(null);
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        makeRequest.get('/user/auth')
        .then(res=> {
            setCurrentUser(res.data[0])
            if(!res.data[0].Admin)
                navigate('/');
        })
        .catch(err => {
            if(err.response?.status === 403) {
                HandleLogout(logout,navigate);
            }
        })
    },[])
    return (
        <div className="containerPrimary">
            <MenuNav user={user}/>            
            <Sponsor/>
            <PrivacyScript/>
            <Sponsor/>
            <Footer/>
        </div>
    );
}

export default Privacy;